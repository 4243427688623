<template>
    <div class="bg-slightGrey">
        <div class="width-90 paddingLr90 bg-white flex-row justify-content-spaceBetween align-items-center">
            <div
                v-for="(item, index) in tabConfig"
                :key="index"
                @click="switchTab(index)"
                class="flex-column justify-content-center align-items-center width-30 margin-t"
            >
                <van-badge v-if="[1].indexOf(index) !== -1" :content="item.count" max="99">
                    <p class="lineHeight fs-mid" :class="{ 'fs-big fw-mid blue': index === tabNum }">
                        {{ item.text }}
                    </p>
                </van-badge>
                <p v-else class="lineHeight fs-mid" :class="{ 'fs-big fw-mid blue': index === tabNum }">
                    {{ item.text }}
                </p>
                <img width="28px" :class="index === tabNum ? 'opacity-8' : 'opacity-0'" src="@/assets/image/icon13.png" alt="" class="margin-bs" />
            </div>
        </div>
        <div v-if="[0, 2].indexOf(tabNum) !== -1" class="width-100 van-dropdown-menu">
            <van-dropdown-menu active-color="#007AFF">
                <van-dropdown-item v-if="isGlobal" v-model="paramsArea" :options="paramsAreaOptions" @change="handleAreaChange" />
                <van-dropdown-item v-model="paramsSubmissionType" :options="paramsSubmissionTypeOptions" @change="handleSubmissionTypeChange" />
                <van-dropdown-item v-model="myDecision" :options="myDecisionOptions" @change="handleMyDecisionChange" />
                <van-dropdown-item
                    :title="startDateRangeTitle"
                    ref="startDateRangDrop"
                    @open="startDateRangShow = true"
                    @close="startDateRangShow = false"
                >
                    <van-calendar
                        v-model="startDateRangShow"
                        type="range"
                        position="bottom"
                        @confirm="handleStartDateRangPick"
                        @close="
                            $refs.startDateRangDrop.toggle(false)
                            rangeShow = $refs.startDateRangDrop.showPopup
                        "
                        color="#1989fa"
                        :min-date="new Date('2021/1/1')"
                    >
                        <template slot="title">
                            <van-button type="danger" round class="clear-range-button" @click="handleStartRangeClear">清除选择</van-button>
                        </template>
                    </van-calendar>
                </van-dropdown-item>
            </van-dropdown-menu>
        </div>
        <div class="width-90 paddingLr90" style="overflow-y: scroll" :style="{ height: `${innerHeight - 168}px` }">
            <van-pull-refresh class="minHeight" v-model="isLoading" @refresh="onRefresh">
                <!-- 列表start -->
                <div
                    v-for="item in list"
                    :key="item.index"
                    @click="jumpto('/shxx', { id: item.id })"
                    class="br-reg border van-overflow-hidden bg-white width-100 margin-t"
                >
                    <div class="titBg-blue titBg margin-bs flex-row justify-content-spaceBetween align-items-center">
                        <div class="width-75 flex-row justify-content-start align-items-center">
                            <img width="24px" height="24px" src="@/assets/image/icon3.png" alt="" />
                            <p class="fs-big width-80 cutText black fw-mid margin-ls">{{ `【${item.submission_type}】${item.location}` }}</p>
                        </div>
                        <p class="blue fs-sml width-20 textAlign-r red">
                            {{ item.is_vetoed ? '驳回' : item.is_complete ? '通过' : '审核中' }}
                        </p>
                    </div>
                    <div class="width-90 fs-sml grey paddingLr90 margin-bs flex-row justify-content-spaceBetween align-items-center">
                        <p class="width-40">申请单位：</p>
                        <p class="width-60 textAlign-r black cutText">{{ item.submit_unit }}</p>
                    </div>
                    <div class="width-90 fs-sml grey paddingLr90 margin-bs flex-row justify-content-spaceBetween align-items-start">
                        <p class="width-40">挖掘时间：</p>
                        <p class="width-60 textAlign-r black">
                            {{ dateFormat(item.start_date) }}<br />至 {{ dateFormat(item.complete_date) }}
                            <span class="red">{{ item.more }}</span>
                        </p>
                    </div>
                </div>
                <!-- 列表end -->
                <van-empty description="暂时没有内容哦~" />
            </van-pull-refresh>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    name: '',
    components: {},
    data() {
        return {
            id: this.$route.query.ID,
            isLoading: false,

            tabNum: 0,
            tabConfig: [
                {
                    value: 'all',
                    text: '全部',
                    params: {},
                    url: '/api/supervision/submission_list/'
                },
                {
                    value: 'waiting',
                    text: '我的待审核',
                    params: {},
                    count: 0,
                    url: '/api/supervision/submission_list/unreviewed_submissions/'
                },
                {
                    value: 'passed',
                    text: '我已审核',
                    params: {},
                    count: 0,
                    url: '/api/supervision/submission_list/reviewed_submissions/'
                }
            ],

            paramsArea: null,
            paramsAreaOptions: [],

            paramsSubmissionType: null,
            paramsSubmissionTypeOptions: [
                { text: '提交类型', value: null },
                { text: '申请', value: '申请' },
                { text: '修改', value: '变更' },
                { text: '延期', value: '延期' }
            ],

            myDecision: '[null,null]',
            myDecisionOptions: [
                // value: '[false,true]' ==> ?is_complete=false&is_vetoed=true
                { text: '审核情况', value: '[null,null]' },
                { text: '通过', value: '[true,false]' },
                { text: '驳回', value: '[true,true]' },
                { text: '审核中', value: '[false,null]' }
            ],

            startDateRangeTitle: '开始日期',
            startDateRangShow: false,

            url: '/api/supervision/submission_list/',
            params: {},
            list: [],

            identity: null,
            isGlobal: null,

            unreviewedCount: 0
        }
    },
    created() {
        // 让底部活跃tab转移到审核
        this.$store.state.tabbarIndex = this.TAB_REVIEW
        // 初始化上方tab中的数量
        const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
        this.tabConfig[1].count = JSON.parse(sessionStorage.getItem('review_counts')).unreviewed_count

        this.identity = userInfo.identity
        this.isGlobal = userInfo.is_global
        if (this.isGlobal) {
            axios
                .get('/api/supervision/area/')
                .then((res) => {
                    this.paramsAreaOptions = res.data.map((area) => ({ value: area.id, text: area.text }))
                    this.paramsAreaOptions.find((area) => area.text === '全市').value = null
                })
                .catch(() => {
                    this.$dialog.alert({
                        message: '无法获取数据，请检查网络后重新打开。'
                    })
                })
        } else {
            this.params.project__information__area = userInfo.area
        }

        const { pre_filter } = this.$route.query
        if (pre_filter === 'wait_for_review') {
            this.tabNum = 1
            this.url = '/api/supervision/submission_list/unreviewed_submissions/'
        } else if (pre_filter === 'is_reviewed') {
            this.tabNum = 2
            this.url = '/api/supervision/submission_list/reviewed_submissions/'
        }
        this.getData()
    },
    mounted() {},
    methods: {
        getData() {
            axios
                .get(this.url, {
                    params: this.params
                })
                .then((res) => {
                    this.list = res.data.results
                })
                .catch(() => {
                    this.$dialog.alert({
                        message: '无法获取数据，请检查网络后重新打开。'
                    })
                })
        },
        onRefresh() {
            this.getData()
            setTimeout(() => {
                this.$toast('刷新成功')
                this.isLoading = false
            }, 1000)
        },
        switchTab(index) {
            this.tabNum = index
            const item = this.tabConfig[index]
            this.url = item.url
            for (const param in item.params) {
                this.params[param] = item.params[param]
            }
            this.getData()
        },
        handleAreaChange(value) {
            this.params.project__information__area = value
            this.getData()
        },
        handleSubmissionTypeChange(value) {
            this.implSubmissionTypeChange(value)
            this.getData()
        },
        implSubmissionTypeChange(value) {
            this.params.submission_type = value
        },
        handleMyDecisionChange(value) {
            const [isComplete, isVoted] = JSON.parse(value)
            // console.log(value, isComplete, isVoted)
            this.params.is_complete = isComplete
            this.params.is_vetoed = isVoted
            this.getData()
        },
        handleStartDateRangPick(dates) {
            this.implRangePick(dates, this.$refs.startDateRangDrop, 'project__information__start_date__range', 'startDateRangeTitle')
            this.getData()
        },
        handleStartRangeClear() {
            this.$refs.startDateRangDrop.toggle()
            this.params.project__information__start_date__range = null
            this.startDateRangeTitle = '巡查日期'
            this.getData()
        }
    }
}
</script>

<style lang="less" scoped>
.van-dropdown-menu {
    height: 48px;
    overflow: hidden;
}
.van-button {
    height: 36px;
}
.clear-range-button {
    width: 50%;
}
.titBg {
    width: 90%;
    padding: 10px 5%;
}
.titBg-red {
    background: linear-gradient(to right, rgba(246, 109, 89, 0.3), rgba(246, 109, 89, 0));
}
.titBg-green {
    background: linear-gradient(to right, rgba(76, 217, 100, 0.3), rgba(76, 217, 100, 0));
}
.titBg-blue {
    background: linear-gradient(to right, rgba(0, 122, 255, 0.3), rgba(0, 122, 255, 0));
}
</style>
